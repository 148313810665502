<template>
  <div class="body"></div>
  <div class="wa">警告！您正在访问钓鱼网站！</div>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
.body {
  min-height: calc(100vh - 130px);
}
.wa {
  width: 100%;
  height: 100px;
  line-height: 100px;
  background: #f00;
  position: fixed;
  left: 0;
  top: 50%;
  margin-top: -50px;
  color: #fff;
  font-size: 40px;
  text-align: center;
}
</style>
